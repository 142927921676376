<template>
  <div class="d-flex flex-stack" style="padding-bottom:10px;">
    <div class="page-title d-flex align-items-center me-3 flex-wrap mb-5 mb-lg-0 lh-1">
      <el-select
          class="form-select-solid w-400"
          placeholder="Select option"
          v-model="selectedSemesterId"
          style="width:400px;"
          @change="updateSemester"
      >
        <el-option 
          v-for="semester in semesters" 
          :label="semester.name+'/'+semester.code+'/'+moment(semester.start_date).format('YYYY.MM.DD')+'~'+moment(semester.end_date).format('YYYY.MM.DD')" 
          :value="semester.id"
          :key="semester.id">
          {{semester.name+'/'+semester.code+'/'+moment(semester.start_date).format('YYYY.MM.DD')+'~'+moment(semester.end_date).format('YYYY.MM.DD')}}
        </el-option>
      </el-select>
      <img 
        src="/media/icons/duotune/coding/cod001.svg" class="cursor-pointer fs-2x edit_icon" title="Edit" 
        style="padding-left: 5px;"
        @click="handleEdit('semester', selectedSemester)">
    </div>
    <div class="d-flex align-items-center py-1">
      <div class="me-4">
        <a
            href="#"
            class="btn btn-sm btn-primary me-2"
            data-bs-toggle="modal"
            data-bs-target="#modal_create_charge"
            id="charge_create_button"
        >
        Create Class Charges
        </a>
        <a
            href="#"
            class="btn btn-sm btn-primary"
            data-bs-toggle="modal"
            data-bs-target="#modal_create_class"
            id="class_create_button"
        >
        Create New Class
        </a>
        <a
            href="#"
            class="btn btn-sm btn-primary"
            data-bs-toggle="modal"
            data-bs-target="#modal_edit_class"
            id="class_edit_button"
            style="display:none;"
        >
        Edit
        </a>
      </div>
    </div>
  </div>
  <div class="card mb-5 mb-xl-10 py-lg-10 px-lg-10" id="kt_profile_details_view">
    <ul id="classes">
      <li v-for="program in classes.values" :key="program.id">
        <span class="caret badge-light-danger badge fs-5 fw-bolder my-2 caret-down" @click="handleClick($event)" v-if="!program.isDeletable">{{program.name}}({{program.code}})</span>
        <span class="badge-light-danger badge fs-5 fw-bolder my-2" v-else>
          {{program.name}}({{program.code}})
        </span> 
        <img 
            src="/media/icons/duotune/coding/cod001.svg" class="cursor-pointer fs-2x edit_icon" title="Edit" 
            @click="handleEdit('program', program)">
        <img 
            src="/media/icons/duotune/general/gen027.svg" class="cursor-pointer fs-2x delete_icon" title="Delete" v-if="program.isDeletable"
            @click="!program.isDeletable? '' : handleDelete(program)">
        <ul class="nested active" v-if="program.levels">
          <li v-for="level in program.levels" :key="level.id">
            <span class="caret badge-light-success badge fs-5 fw-bolder my-2 caret-down" @click="handleClick($event)" v-if="!level.isDeletable">{{level.name}}({{level.code}})</span>
            <span class="badge-light-success badge fs-5 fw-bolder my-2" v-else>
              {{level.name}}({{level.code}})
            </span> 
            <img 
                src="/media/icons/duotune/coding/cod001.svg" class="cursor-pointer fs-2x edit_icon" title="Edit" 
                @click="handleEdit('level', level)">
            <img 
                src="/media/icons/duotune/general/gen027.svg" class="cursor-pointer fs-2x delete_icon" title="Delete" v-if="level.isDeletable"
                @click="!level.isDeletable? '' : handleDelete(level)">
            <ul class="nested active" v-if="level.sessions">
              <li v-for="session in level.sessions" :key="session.id">
                <span class="caret badge-light-primary badge fs-5 fw-bolder my-2 caret-down" @click="handleClick($event)" v-if="!session.isDeletable">{{session.name}}({{session.code}})</span>
                <span class="badge-light-success badge fs-5 fw-bolder my-2" v-else>
                  {{session.name}}({{session.code}})
                </span>
                <img 
                    src="/media/icons/duotune/coding/cod001.svg" class="cursor-pointer fs-2x edit_icon" title="Edit" 
                    @click="handleEdit('session', session)">
                <img 
                    src="/media/icons/duotune/general/gen027.svg" class="cursor-pointer fs-2x delete_icon" title="Delete" v-if="session.isDeletable"
                    @click="handleDelete(session)">
                <ul class="nested active" v-if="session.subjects">
                  <li v-for="subject in session.subjects" :key="subject.id">
                    <span class="badge-light-warning badge fs-5 fw-bolder my-2" style="cursor:pointer;" @click="linkSubjectPage(subject.id)">
                      {{subject.name}}({{subject.code}})
                    </span>
                    <span>
                      Time Range : {{subject.start_time}}~{{subject.end_time}} / Hours : {{subject.hours}} 
                      <span v-if="subject.instructor">/ Instructor : {{subject.instructor.first_name}} {{subject.instructor.last_name}}</span>
                      <img 
                        src="/media/icons/duotune/coding/cod001.svg" class="cursor-pointer fs-2x edit_icon" title="Edit" 
                        @click="handleEdit('subject', subject)">
                      <img 
                        src="/media/icons/duotune/general/gen027.svg" class="cursor-pointer fs-2x delete_icon" title="Delete" 
                        @click="handleDelete(subject)">
                    </span>
                  </li> 
                </ul>
              </li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
  </div>
  <CreateClass
      v-bind:instructors="instructors"
      v-bind:daysOfWeek="daysOfWeek"
  ></CreateClass>
  <EditClass
      v-bind:data="selectedClassData"
      v-bind:type="selectedClassType"
      v-bind:getClasses="getClasses"
      v-bind:updateSemester="updateSemester"
      v-bind:instructors="instructors"
      v-bind:daysOfWeek="daysOfWeek"
  ></EditClass>
  <CreateCharge
      v-bind:semesterId="selectedSemesterId">
  </CreateCharge>
</template>

<script lang="ts">
import { defineComponent, onBeforeMount, onMounted, reactive, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import CreateClass from "@/views/academics/modals/CreateClassModal.vue";
import EditClass from "@/views/academics/modals/EditClassModal.vue";
import CreateCharge from "@/views/academics/modals/CreateChargeModal.vue";
import ApiService from "@/core/services/ApiService";
import moment from "moment";
import { ElMessageBox } from "element-plus";
import router from "@/router";

export default defineComponent({
  name: "account-overview",
  components: {
    CreateClass,
    EditClass,
    CreateCharge
  },
  setup() {
    const semesters = ref([] as any);
    const classes = reactive([] as any);
    const selectedSemester = ref({} as any);
    const selectedClassData = ref({} as any);
    const selectedClassType = ref('');
    const instructors = ref<any>([]);
    const selectedSemesterId = ref(0);
    const daysOfWeek = [{label :'Sun', value : 0}, 
                        {label :'Mon', value : 1},
                        {label :'Tue', value : 2},
                        {label :'Wed', value : 3},
                        {label :'Thu', value : 4},
                        {label :'Fri', value : 5},
                        {label :'Sat', value : 6}];

    const getSemesters = () : any => {
        return new Promise((resolve, reject) => {            
            ApiService.setHeader();
            ApiService.get("class/semesters")
                .then(({ data }) => {
                    let sortedData = data.data.sort(function(a,b) { 
                          if(a.id == b.id) return 0;

                          if (Date.parse(a.start_date!) < Date.parse(b.start_date!))
                              return 1;
                          if (Date.parse(a.start_date!) > Date.parse(b.start_date!))
                              return -1;
                          return 0;
                      });
                    semesters.value = sortedData;
                    resolve(sortedData);
                })
                .catch((error) => {
                    reject(new Error ('getSemesters error => '+error));
                });
        });
    }

    const updateSemester = async() => {
      getSemesters().then((data) => {
        if(data){
          let currentSemester = data.filter(semester => semester.id == selectedSemesterId.value)[0];
          if(!currentSemester){
            let today = new Date();
            let currentSemester = data.filter(semester => (semester.start_date <= today && semester.end_date >= today));

            if(currentSemester.length > 0){
              selectedSemester.value = currentSemester[0];
            }
            else{
              selectedSemester.value = data[0];
            }
            
            selectedSemesterId.value = selectedSemester.value.id;
          }
          else{
            selectedSemester.value = currentSemester;
          }
          getClasses();
          getInstructors();
        }
      });
    }

    const getClasses = async() => {
        await new Promise((resolve, reject) => { 
            ApiService.setHeader();   
            ApiService.get("class/"+selectedSemester.value.id)
                .then(({ data }) => {
                  data.data.forEach(program => {
                    program.isDeletable = program.levels  == null || program.levels.length == 0;
                    program.levels.forEach(level => {
                      level.isDeletable = level.sessions  == null || level.sessions.length == 0;
                      level.sessions.forEach(session => {
                        session.isDeletable = session.subjects == null || session.subjects.length == 0;
                        session.subjects.forEach(subject =>{
                          subject.isDeletable = true;
                        })
                      })
                    })
                  });
                  classes.values= data.data;
                  resolve(data.data);
                })
                .catch((error) => {
                    reject(new Error ('getSemesters error => '+error));
                });
        });
    }

    const handleEdit = (type, data) => {
        selectedClassData.value = data;
        selectedClassType.value = type;
        let editButton = document.getElementById('class_edit_button');
        editButton?.click();
    };

    const handleDelete = (data) => {
      ElMessageBox.confirm('Are you sure to delete this class?')
            .then(() => {
                let classType = data.semester_id != undefined ? 'program' : data.program_id != undefined ? 'level' : data.level_id != undefined ? 'session' : 'subject';   
                ApiService.setHeader();
                ApiService.delete("class/"+classType+"/"+data.id)
                  .then(() => {
                      getClasses(); 
                  })
                  .catch(({ response }) => {
                      console.log('handleDelete error => '+response.data.errors);
                  });
            });
    };

    const handleClick = (el) =>{
        el.target.parentElement?.querySelector(".nested")?.classList.toggle("active");
        el.target.classList.toggle("caret-down");
    }

    const linkSubjectPage = (subject_id) => {
        router.push({ name: "subject", params: { id: subject_id } });
    }

    const getInstructors = async() =>{
      await new Promise((resolve, reject) => {  
        ApiService.setHeader();
        ApiService.get("staff")
              .then(({ data }) => {
                  instructors.value = data.data;
                  resolve(data);
              })
              .catch(({ response }) => {
                console.log('getAdvisors error => '+response.data.errors);
                reject(response.data.errors);
              });
      });
    }

    onBeforeMount(async() => {
      updateSemester();
    })

    onMounted(() => {
      setCurrentPageBreadcrumbs("Class", ["Academics"]);
    });

    return {
      handleEdit,
      handleDelete,
      classes,
      selectedSemester,
      semesters,
      moment,
      handleClick,
      selectedClassData,
      selectedClassType,
      getClasses,
      linkSubjectPage,
      instructors,
      daysOfWeek,
      selectedSemesterId,
      updateSemester
    };
  },
});
</script>

<style scoped>
* >>> .delete_icon {
    opacity: 0.3;
}

* >>> .delete_icon:hover {
    pointer-events: all;
    opacity: 0.7 !important;
}

* >>> .edit_icon {
    filter: invert(80%) sepia(120%) saturate(2000%) hue-rotate(103deg) brightness(40%) contrast(80%);
}

* >>> .edit_icon:hover {
    pointer-events: all;
    -ms-transform: rotate(15deg); /* IE 9 */
    -webkit-transform: rotate(15deg); /* Chrome, Safari, Opera */
    transform: rotate(15deg);
}

* >>> ul, #classes {
  list-style-type: none;
}

* >>> #classes {
  margin: 0;
  padding: 0;  
  font-size: 16px;
}

* >>> ul li{
  line-height:2.2;
}

* >>> .caret {
  cursor: pointer;
  user-select: none; /* Prevent text selection */
}

* >>> .caret::before {
  content: "\25B6";
  color: black;
  display: inline-block;
  margin-right: 12px;
}

* >>> .caret-down::before {
  transform: rotate(90deg);
}

* >>> .nested {
  display: none;
}

* >>> .active {
  display: block;
}
</style>