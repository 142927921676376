
import { defineComponent, onMounted, ref, computed, onBeforeMount } from "vue";
import { StepperComponent } from "@/assets/ts/components/_StepperComponent";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import moment from "moment";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
  name: "create-class-modal",
  components: {
  },
  props:{
    instructors: [] as any,
    daysOfWeek: [] as any,
  },
  setup() {
    const _stepperObj = ref<StepperComponent | null>(null);
    const createClassRef = ref<HTMLElement | null>(null);
    const currentStepIndex = ref(0);
    const selectedDays = ref<any>([]);
    const skillAreas = ref<any>([]);
    const skill = ref('');

    const classData = ref({
      semester : {id: 0, name:'', code:'', period: [], start_date:null, end_date:null},
      program : {id:0, name:'', code:'', semester_id : null as any},
      level : {id:0, name:'', code:'', program_id : null as any},
      session : {id:0, name:'', code:'', level_id : null as any, hours_per_week: 0},
      subject : {id:0, name:'', code:'', timePeriod: [] as any, start_time:null, end_time: null, hours:0, instructor: '', skill_area: '', class_days: '', session_id: null as any},
    });

    const semesters = ref([] as any);
    const programs = ref([] as any);
    const levels = ref([] as any);
    const sessions = ref([] as any);

    onMounted(() => {
      _stepperObj.value = StepperComponent.createInsance(
        createClassRef.value as HTMLElement
      );
    });

    const totalSteps = computed(() => {
      if (!_stepperObj.value) {
        return;
      }

      return _stepperObj.value.totatStepsNumber;
    });

    const addToSkillArea = () =>{
      if(skill.value && skill.value != ''){
        skillAreas.value.push(skill.value);
        skill.value = ''
      }
    }

    const removeFromSkillArea = (index) =>{
      skillAreas.value.splice(index, 1);
    }

    const previousStep = () => {
      if (!_stepperObj.value) {
        return;
      }

      currentStepIndex.value--;

      _stepperObj.value.goPrev();
    };

    const handleStep = () => {
      let isValidated = true;
      if(currentStepIndex.value == 0)
      {
        let selectedSemesterId = classData.value.semester.id;
        if(selectedSemesterId == 0){
          if(!classData.value.semester.code  
              || !classData.value.semester.name 
              || !classData.value.semester.period[0]
              || !classData.value.semester.period[1]){
            isValidated = false;
          }
          programs.value = [];
        }
        else{
          getPrograms(selectedSemesterId);
          classData.value.program.semester_id = selectedSemesterId;
        }
      }
      else if(currentStepIndex.value == 1)
      {
        let selectedProgramId = classData.value.program.id;
        if(selectedProgramId == 0){
          if(!classData.value.program.code || !classData.value.program.name){
            isValidated = false;
          }
          levels.value = [];
        }
        else{
          getLevels(selectedProgramId);
          classData.value.level.program_id = selectedProgramId;
        }
      }
      else if(currentStepIndex.value == 2)
      {
        let selectedLevelId = classData.value.level.id;
        if(selectedLevelId == 0){
          if(!classData.value.level.code || !classData.value.level.name){
            isValidated = false;
          }
          sessions.value = [];
        }
        else{
          getSessions(classData.value.level.id);
          classData.value.session.level_id = selectedLevelId;
        }
      }
      else if(currentStepIndex.value == 3)
      {
        let selectedSessionId = classData.value.session.id;
        if(selectedSessionId == 0){
          if(!classData.value.session.code || !classData.value.session.name){
            isValidated = false;
          }
        }
        else{
          classData.value.subject.session_id = selectedSessionId;
        }
      }

      if(isValidated == false){
        Swal.fire({
              text: "Please fill out all required fields",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });
            return false;
      }

      currentStepIndex.value++;

      if (!_stepperObj.value) {
        return;
      }

      _stepperObj.value.goNext();
    };

    const createClass = async (formData) => {
        formData.class_days = selectedDays.value.join(":");
        ApiService.setHeader();
        ApiService.post("class", formData)
            .then(() => {
              Swal.fire({
                  text: "New class is created successfully",
                  icon: "success",
                  buttonsStyling: false,
                  confirmButtonText: "Ok, got it!",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                }).then(() => {
                  window.location.reload();
                });
            })
            .catch(({ response }) => {
                console.log('createClass error => '+response.data.errors);
                Swal.fire({
                  text: "Failed to create the new class",
                  icon: "error",
                  buttonsStyling: false,
                  confirmButtonText: "Ok, got it!",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                });
            });
    }

    const formSubmit = () => {
      if(!classData.value.subject.code  
              || !classData.value.subject.name 
              || !classData.value.subject.timePeriod[0]
              || !classData.value.subject.timePeriod[1]
              || !classData.value.subject.hours){
            Swal.fire({
              text: "Please fill out all required fields",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });
            return false;
      }

      classData.value.semester.start_date = classData.value.semester.period[0];
      classData.value.semester.end_date = classData.value.semester.period[1];
      classData.value.subject.start_time = classData.value.subject.timePeriod[0].toLocaleTimeString('it-IT');
      classData.value.subject.end_time = classData.value.subject.timePeriod[1].toLocaleTimeString('it-IT');
      classData.value.subject.skill_area = skillAreas.value.join(';');
      classData.value.subject.class_days = selectedDays.value.join(';');
      createClass(classData.value);
    };

    const getSemesters = async () => {
        await new Promise((resolve, reject) => {
          ApiService.setHeader();            
            ApiService.get("class/semesters")
                .then(({ data }) => {
                    semesters.value = data.data.sort(function(a,b) { 
                          if(a.id == b.id) return 0;

                          if (Date.parse(a.start_date!) < Date.parse(b.start_date!))
                              return 1;
                          if (Date.parse(a.start_date!) > Date.parse(b.start_date!))
                              return -1;
                          return 0;
                      });
                    resolve(data.data);
                })
                .catch((error) => {
                    console.log('getSemesters error => '+error);
                    reject(new Error ('getSemesters error => '+error));
                });
        });
    }

    const getPrograms = async (id) => {
        await new Promise((resolve, reject) => {   
            ApiService.setHeader();         
            ApiService.get("class/programs/"+id)
                .then(({ data }) => {
                    programs.value = data.data;
                    resolve(data.data);
                })
                .catch((error) => {
                    console.log('getPrograms error => '+error);
                    reject(new Error ('getPrograms error => '+error));
                });
        });
    }

    const getLevels = async (id) => {
        await new Promise((resolve, reject) => { 
          ApiService.setHeader();           
            ApiService.get("class/levels/"+id)
                .then(({ data }) => {
                    levels.value = data.data;
                    resolve(data.data);
                })
                .catch((error) => {
                    console.log('getLevels error => '+error);
                    reject(new Error ('getLevels error => '+error));
                });
        });
    }

    const getSessions = async (id) => {
        await new Promise((resolve, reject) => { 
            ApiService.setHeader();           
            ApiService.get("class/sessions/"+id)
                .then(({ data }) => {
                    sessions.value = data.data;
                    resolve(data.data);
                })
                .catch((error) => {
                    console.log('getSessions error => '+error);
                    reject(new Error ('getSessions error => '+error));
                });
        });
    }

    onBeforeMount(async() => {
        await getSemesters();
    })

    return {
      handleStep,
      formSubmit,
      previousStep,
      createClassRef,
      currentStepIndex,
      totalSteps,
      classData,
      semesters,
      programs,
      levels,
      sessions,
      moment,
      selectedDays,
      skillAreas,
      addToSkillArea,
      skill,
      removeFromSkillArea
    };
  },
});
