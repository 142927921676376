
import { defineComponent, ref, watch } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import moment from "moment";
import ApiService from "@/core/services/ApiService";
import { ElMessageBox } from "element-plus";

export default defineComponent({
  name: "edit-class-modal",
  components: {
  },
  props:{
    data:{} as any,
    type:String,
    instructors: [] as any,
    daysOfWeek: [] as any,
    getClasses : {
      type:Function,
      default: () => ({}),
    },
    updateSemester : {
      type:Function,
      default: () => ({}),
    },
  },
  setup(props) {
    const createClassRef = ref<HTMLElement | null>(null);
    const formdata = ref(props.data);
    const selectedDays = ref<any>([]);
    const skillAreas = ref<any>([]);
    const skill = ref('');
    
    watch(() => props.data, (cur, prev)=>{
        skillAreas.value = [];
        selectedDays.value = [];
        skill.value = '';
        if(cur !== prev){
            formdata.value = props.data;
            if(props.type == 'semester'){
                formdata.value.period = [];
                formdata.value.period[0] = props.data.start_date;
                formdata.value.period[1] = props.data.end_date;
            }
            else if(props.type == 'subject'){
                formdata.value.timePeriod = [];
                let startTime = new Date();

                let [start_hours, start_minutes, start_seconds] = props.data.start_time.split(':');
                startTime.setHours(start_hours);
                startTime.setMinutes(start_minutes);
                startTime.setSeconds(start_seconds);
                formdata.value.timePeriod[0] = startTime;

                let endTime = new Date();
                let [end_hours, end_minutes, end_seconds] = props.data.end_time.split(':');
                endTime.setHours(end_hours);
                endTime.setMinutes(end_minutes);
                endTime.setSeconds(end_seconds);
                formdata.value.timePeriod[1] = endTime;

                if(props.data.skill_area){
                  skillAreas.value = props.data.skill_area.split(';');
                }
                if(props.data.class_days){
                  selectedDays.value = props.data.class_days.split(';').map( Number );
                }              
            }
        }
    })

    const formSubmit = () => {
        if(props.type == 'semester'){
            formdata.value.start_date = formdata.value.period[0];
            formdata.value.end_date = formdata.value.period[1];
        }
        else if(props.type == 'subject'){
            formdata.value.start_time = formdata.value.timePeriod[0].toLocaleTimeString('it-IT');
            formdata.value.end_time = formdata.value.timePeriod[1].toLocaleTimeString('it-IT');
            formdata.value.skill_area = skillAreas.value.join(';');
            formdata.value.class_days = selectedDays.value.join(';');
            delete formdata.value.instructor;
        }

        editClass(formdata.value);
    };

    const editClass = (data) => {
      ApiService.setHeader();
      ApiService.update("class/"+props.type, data.id, data)
        .then(() => {
            Swal.fire({
                  text: "Updated successfully",
                  icon: "success",
                  buttonsStyling: false,
                  confirmButtonText: "Ok, got it!",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                }).then(() => {
                    props.getClasses();
                    let editCloseButton = document.getElementById('edit_class_close_button');
                    editCloseButton?.click();
                });
            
        })
        .catch(({ response }) => {
          console.log(response);
            Swal.fire({
                  text: "Update failed",
                  icon: "error",
                  buttonsStyling: false,
                  confirmButtonText: "Ok, got it!",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                });
        });
    };

    const addToSkillArea = () =>{
      if(skill.value && skill.value != ''){
        skillAreas.value.push(skill.value);
        skill.value = ''
      }
    }

    const removeFromSkillArea = (index) =>{
      skillAreas.value.splice(index, 1);
    }

    const deleteSemeter = () =>{
      ElMessageBox.confirm('Are you sure you want to delete this semester? Once deleted, all of the nested classes will also be deleted.')
            .then(() => {
                ApiService.setHeader();
                ApiService.delete("class/semester/"+formdata.value.id)
                  .then(() => {
                      props.updateSemester();
                      let editCloseButton = document.getElementById('edit_class_close_button');
                      editCloseButton?.click(); 
                  })
                  .catch(({ response }) => {
                    console.log(response);
                      Swal.fire({
                          text: "Delete failed.",
                          icon: "error",
                          buttonsStyling: false,
                          confirmButtonText: "Ok, got it!",
                          customClass: {
                            confirmButton: "btn btn-primary",
                          },
                      });
                  });
            });
    }

    return {
      formSubmit,
      createClassRef,
      moment,
      formdata,
      selectedDays,
      skillAreas,
      skill,
      addToSkillArea,
      removeFromSkillArea,
      deleteSemeter,
    };
  },
});
