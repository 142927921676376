<template>
  <div
    class="modal fade"
    id="modal_create_charge"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered mw-650px">
      <div class="modal-content">
        <div class="modal-header">
          <h2>Create tuition charges for students</h2>

          <div
            class="btn btn-sm btn-icon btn-active-color-primary"
            data-bs-dismiss="modal"
            id="create_charge_close_button"
          >
            <span class="svg-icon svg-icon-1">
              <inline-svg src="/media/icons/duotune/arrows/arr061.svg" />
            </span>
          </div>
        </div>

        <div class="modal-body py-lg-10 px-lg-10">
            <div class="flex-row-fluid px-lg-15">
              <el-form
                id="kt_modal_create_charge_form"
                class="form"
                :model="formdata"
                ref="formRef"
              >
                  <div class="w-100">
                    <div class="fv-row mb-10">
                      <div>
                        <label
                          class="d-flex align-items-center fs-5 fw-bold mb-2"
                        >
                          <span class="required">Program</span>
                        </label>
                        <el-form-item prop="program_id">
                          <el-select
                              class="form-select-solid w-100"
                              placeholder="Select option"
                              v-model="formdata.program_id"
                          >
                            <el-option v-for="program in programs" 
                              :key="program.id" 
                              :label="program.name+'/'+program.code"
                              :value="program.id">
                                {{program.name}}/{{program.code}}
                            </el-option>
                          </el-select>
                        </el-form-item>
                      </div>
                      <div>
                        <label
                          class="d-flex align-items-center fs-5 fw-bold mb-2"
                        >
                          <span class="required">Date</span>
                        </label>
                        <el-form-item prop="record_date">
                          <el-date-picker
                            v-model="formdata.record_date"
                            type="date"
                            start-placeholder="transaction date"
                            class="w-100"
                            format="MM/DD/YYYY">
                          </el-date-picker>
                        </el-form-item>
                      </div>
                      <div>
                        <label
                          class="d-flex align-items-center fs-5 fw-bold mb-2"
                        >
                          <span class="required">Amount</span>
                        </label>
                        <el-form-item prop="program_amount">
                          <input
                            v-model="formdata.amount"
                            min="0.01" 
                            step="0.01"
                            type="number"
                            class="form-control form-control-lg form-control-solid"
                          />
                        </el-form-item>
                      </div>
                      <div>
                      <label
                        class="d-flex align-items-center fs-5 fw-bold mb-2"
                      >
                        <span>Memo</span>
                      </label>
                        <el-form-item prop="memo">
                        <input
                            v-model="formdata.memo"
                            style="width:100%;"
                            class="form-control form-control-lg form-control-solid"
                        />
                        </el-form-item>
                    </div>
                  </div>
                </div>
              <div class="d-flex flex-stack" style="float:right">
                <div>
                  <button
                    type="button"
                    class="btn btn-lg btn-primary"
                    @click="formSubmit()"
                    >
                    <span class="indicator-label">
                        Submit
                        <span class="svg-icon svg-icon-3 ms-2 me-0">
                        <inline-svg
                            src="media/icons/duotune/arrows/arr064.svg"
                        />
                        </span>
                    </span>
                  </button>
              </div>
            </div>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<style lang="scss" scoped>
.el-input--suffix .el-input__inner {
  background-color: #f5f8fa;
}

.el-input__inner {
  background-color: #f5f8fa;
}
</style>

<script lang="ts">
import { defineComponent, onMounted, ref, watch } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import moment from "moment";
import ApiService from "@/core/services/ApiService";
import store from "@/store";

export default defineComponent({
  name: "create-charge-modal",
  props:{
    semesterId: Number,
  },
  setup(props) {
    const formdata = ref({} as any);
    const programs = ref([] as any);
    const user = store.getters.currentUser;

    const getPrograms = async () => {
        await new Promise((resolve, reject) => {   
            ApiService.setHeader();         
            ApiService.get("class/programs/"+props.semesterId)
                .then(({ data }) => {
                    programs.value = data.data;
                    resolve(data.data);
                })
                .catch((error) => {
                    console.log('getPrograms error => '+error);
                    reject(new Error ('getPrograms error => '+error));
                });
        });
    }

    watch(() => props.semesterId, (cur, prev)=>{
        getPrograms();
    })

    const formSubmit = () => {
        formdata.value.created_by = user.id;
        ApiService.setHeader();
        ApiService.post("class/program-charge", formdata.value)
        .then(() => {
            Swal.fire({
                  text: "Program charges are created for students successfully",
                  icon: "success",
                  buttonsStyling: false,
                  confirmButtonText: "Ok, got it!",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                }).then(() => {
                    let editCloseButton = document.getElementById('create_charge_close_button');
                    editCloseButton?.click();
                });
            
        })
        .catch(({ response }) => {
            Swal.fire({
                  text: "Create failed!",
                  icon: "error",
                  buttonsStyling: false,
                  confirmButtonText: "Ok, got it!",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                });
            console.log(response);
        });
    };

    onMounted(() => {
      getPrograms();
    });

    return {
      formSubmit,
      moment,
      formdata,
      programs
    };
  },
});
</script>
