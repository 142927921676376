
import { defineComponent, onMounted, ref, watch } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import moment from "moment";
import ApiService from "@/core/services/ApiService";
import store from "@/store";

export default defineComponent({
  name: "create-charge-modal",
  props:{
    semesterId: Number,
  },
  setup(props) {
    const formdata = ref({} as any);
    const programs = ref([] as any);
    const user = store.getters.currentUser;

    const getPrograms = async () => {
        await new Promise((resolve, reject) => {   
            ApiService.setHeader();         
            ApiService.get("class/programs/"+props.semesterId)
                .then(({ data }) => {
                    programs.value = data.data;
                    resolve(data.data);
                })
                .catch((error) => {
                    console.log('getPrograms error => '+error);
                    reject(new Error ('getPrograms error => '+error));
                });
        });
    }

    watch(() => props.semesterId, (cur, prev)=>{
        getPrograms();
    })

    const formSubmit = () => {
        formdata.value.created_by = user.id;
        ApiService.setHeader();
        ApiService.post("class/program-charge", formdata.value)
        .then(() => {
            Swal.fire({
                  text: "Program charges are created for students successfully",
                  icon: "success",
                  buttonsStyling: false,
                  confirmButtonText: "Ok, got it!",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                }).then(() => {
                    let editCloseButton = document.getElementById('create_charge_close_button');
                    editCloseButton?.click();
                });
            
        })
        .catch(({ response }) => {
            Swal.fire({
                  text: "Create failed!",
                  icon: "error",
                  buttonsStyling: false,
                  confirmButtonText: "Ok, got it!",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                });
            console.log(response);
        });
    };

    onMounted(() => {
      getPrograms();
    });

    return {
      formSubmit,
      moment,
      formdata,
      programs
    };
  },
});
