<template>
  <div
    class="modal fade"
    id="modal_create_class"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered mw-900px">
      <div class="modal-content">
        <div class="modal-header">
          <h2>Create class</h2>

          <div
            class="btn btn-sm btn-icon btn-active-color-primary"
            data-bs-dismiss="modal"
          >
            <span class="svg-icon svg-icon-1">
              <inline-svg src="/media/icons/duotune/arrows/arr061.svg" />
            </span>
          </div>
        </div>

        <div class="modal-body py-lg-10 px-lg-10">
          <div
            class="
              stepper stepper-pills stepper-column
              d-flex
              flex-column flex-xl-row flex-row-fluid
            "
            id="modal_create_class_stepper"
            ref="createClassRef"
          >
            <div
              class="
                d-flex
                justify-content-center justify-content-xl-start
                flex-row-auto
                w-100 w-xl-300px
              "
            >
              <div class="stepper-nav ps-lg-10">
                <div class="stepper-item current" data-kt-stepper-element="nav">
                  <div class="stepper-line w-40px"></div>
                  <div class="stepper-icon w-40px h-40px">
                    <i class="stepper-check fas fa-check"></i>
                    <span class="stepper-number">1</span>
                  </div>
                  <div class="stepper-label">
                    <h3 class="stepper-title">Term</h3>
                    <div class="stepper-desc">Select or create a term</div>
                  </div>
                </div>

                <div class="stepper-item" data-kt-stepper-element="nav">
                  <div class="stepper-line w-40px"></div>
                  <div class="stepper-icon w-40px h-40px">
                    <i class="stepper-check fas fa-check"></i>
                    <span class="stepper-number">2</span>
                  </div>
                  <div class="stepper-label">
                    <h3 class="stepper-title">Program</h3>
                    <div class="stepper-desc">Select or create a program</div>
                  </div>
                </div>

                <div class="stepper-item" data-kt-stepper-element="nav">
                  <div class="stepper-line w-40px"></div>
                  <div class="stepper-icon w-40px h-40px">
                    <i class="stepper-check fas fa-check"></i>
                    <span class="stepper-number">3</span>
                  </div>
                  <div class="stepper-label">
                    <h3 class="stepper-title">Level</h3>
                    <div class="stepper-desc">Select or create a level</div>
                  </div>
                </div>

                <div class="stepper-item" data-kt-stepper-element="nav">
                  <div class="stepper-line w-40px"></div>
                  <div class="stepper-icon w-40px h-40px">
                    <i class="stepper-check fas fa-check"></i>
                    <span class="stepper-number">4</span>
                  </div>
                  <div class="stepper-label">
                    <h3 class="stepper-title">Session</h3>
                    <div class="stepper-desc">Select or create a session</div>
                  </div>
                </div>

                <div class="stepper-item" data-kt-stepper-element="nav">
                  <div class="stepper-line w-40px"></div>
                  <div class="stepper-icon w-40px h-40px">
                    <i class="stepper-check fas fa-check"></i>
                    <span class="stepper-number">5</span>
                  </div>
                  <div class="stepper-label">
                    <h3 class="stepper-title">Subject</h3>

                    <div class="stepper-desc">Select or create a subject</div>
                  </div>
                </div>
              </div>
            </div>

            <div class="flex-row-fluid py-lg-5 px-lg-15">
              <el-form
                id="kt_modal_create_app_form"
                class="form"
                :model="classData"
                ref="formRef"
              >
                <div class="current" data-kt-stepper-element="content">
                  <div class="w-100">
                    <div class="fv-row mb-10">
                      <label
                        class="d-flex align-items-center fs-5 fw-bold mb-2"
                      >
                        <span class="title">Term</span>
                      </label>
                      <el-select
                          class="form-select-solid w-100"
                          placeholder="Select option"
                          v-model="classData.semester.id"
                      >
                        <el-option label="New" :value="0">New</el-option>
                        <el-option v-for="semester in semesters" 
                          :key="semester.id" 
                          :label="semester.name+'/'+semester.code+'/'+moment(semester.start_date).format('YYYY.MM.DD')+'~'+moment(semester.end_date).format('YYYY.MM.DD')" 
                          :value="semester.id">
                            {{semester.name}}/{{semester.code}}/{{moment(semester.start_date).format('YYYY.MM.DD')}}~{{moment(semester.end_date).format('YYYY.MM.DD')}}
                        </el-option>
                      </el-select>
                    </div>
                    <div v-if="classData.semester.id==0">
                      <div>
                        <label
                          class="d-flex align-items-center fs-5 fw-bold mb-2"
                        >
                          <span class="required">Name</span>
                        </label>
                        <el-form-item prop="semester_name">
                          <input
                            type="text"
                            name="classData.semester.name"
                            class="form-control form-control-lg form-control-solid"
                            v-model="classData.semester.name"
                          />
                        </el-form-item>
                      </div>
                      <div>
                        <label
                          class="d-flex align-items-center fs-5 fw-bold mb-2"
                        >
                          <span class="required">Code</span>
                        </label>
                        <el-form-item prop="semester_code">
                          <input
                            type="text"
                            class="form-control form-control-lg form-control-solid"
                            name="classData.semester.code"
                            v-model="classData.semester.code"
                          />
                        </el-form-item>
                      </div>
                      <div>
                        <label
                          class="d-flex align-items-center fs-5 fw-bold mb-2"
                        >
                          <span class="required">Period</span>
                        </label>
                        <el-date-picker
                          v-model="classData.semester.period"
                          type="daterange"
                          range-separator="To"
                          start-placeholder="Start date"
                          end-placeholder="End date"
                          class="w-100"
                          format="MM/DD/YYYY">
                        </el-date-picker>
                      </div>
                    </div>
                  </div>
                </div>

                <div data-kt-stepper-element="content">
                  <div class="w-100">
                    <div class="fv-row mb-10">
                      <label
                        class="d-flex align-items-center fs-5 fw-bold mb-2"
                      >
                        <span class="title">Program</span>
                      </label>
                      <el-select
                          class="form-select-solid w-100"
                          placeholder="Select option"
                          v-model="classData.program.id"
                      >
                        <el-option v-for="program in programs" 
                          :key="program.id" 
                          :label="program.name+'/'+program.code"
                          :value="program.id">
                            {{program.name}}/{{program.code}}
                        </el-option>
                        <el-option label="New" :value="0">New</el-option>
                      </el-select>
                    </div>
                    <div v-if="classData.program.id==0">
                      <div>
                        <label
                          class="d-flex align-items-center fs-5 fw-bold mb-2"
                        >
                          <span class="required">Name</span>
                        </label>
                        <el-form-item prop="program_name">
                          <input
                            type="text"
                            class="form-control form-control-lg form-control-solid"
                            name="classData.program.name"
                            v-model="classData.program.name"
                          />
                        </el-form-item>
                      </div>
                      <div>
                        <label
                          class="d-flex align-items-center fs-5 fw-bold mb-2"
                        >
                          <span class="required">Code</span>
                        </label>
                        <el-form-item prop="program_code">
                          <input
                            type="text"
                            class="form-control form-control-lg form-control-solid"
                            name="classData.program.code"
                            v-model="classData.program.code"
                          />
                        </el-form-item>
                      </div>
                    </div>
                  </div>
                </div>

                <div data-kt-stepper-element="content">
                  <div class="w-100">
                    <div class="fv-row mb-10">
                      <label
                        class="d-flex align-items-center fs-5 fw-bold mb-2"
                      >
                        <span class="title">Level</span>
                      </label>
                      <el-select
                          class="form-select-solid w-100"
                          placeholder="Select option"
                          v-model="classData.level.id"
                      >
                        <el-option v-for="level in levels" 
                          :key="level.id" 
                          :label="level.name+'/'+level.code"
                          :value="level.id">
                            {{level.name}}/{{level.code}}
                        </el-option>
                        <el-option label="New" :value="0">New</el-option>
                      </el-select>
                    </div>
                    <div v-if="classData.level.id==0">
                      <div>
                        <label
                          class="d-flex align-items-center fs-5 fw-bold mb-2"
                        >
                          <span class="required">Name</span>
                        </label>
                        <el-form-item prop="level_name">
                          <input
                            type="text"
                            class="form-control form-control-lg form-control-solid"
                            name="classData.level.name"
                            v-model="classData.level.name"
                          />
                        </el-form-item>
                      </div>
                      <div>
                        <label
                          class="d-flex align-items-center fs-5 fw-bold mb-2"
                        >
                          <span class="required">Code</span>
                        </label>
                        <el-form-item prop="level_code">
                          <input
                            type="text"
                            class="form-control form-control-lg form-control-solid"
                            name="classData.level.code"
                            v-model="classData.level.code"
                          />
                        </el-form-item>
                      </div>
                    </div>
                  </div>
                </div>

                <div data-kt-stepper-element="content">
                  <div class="w-100">
                    <div class="fv-row mb-10">
                      <label
                        class="d-flex align-items-center fs-5 fw-bold mb-2"
                      >
                        <span class="title">Session</span>
                      </label>
                      <el-select
                          class="form-select-solid w-100"
                          placeholder="Select option"
                          v-model="classData.session.id"
                      >
                        <el-option v-for="session in sessions" 
                          :key="session.id" 
                          :label="session.name+'/'+session.code"
                          :value="session.id">
                            {{session.name}}/{{session.code}}
                        </el-option>
                        <el-option label="New" :value="0">New</el-option>
                      </el-select>
                    </div>
                    <div v-if="classData.session.id==0">
                      <div>
                        <label
                          class="d-flex align-items-center fs-5 fw-bold mb-2"
                        >
                          <span class="required">Name</span>
                        </label>
                        <el-form-item prop="session_name">
                          <input
                            type="text"
                            class="form-control form-control-lg form-control-solid"
                            name="classData.session.name"
                            v-model="classData.session.name"
                          />
                        </el-form-item>
                      </div>
                      <div>
                        <label
                          class="d-flex align-items-center fs-5 fw-bold mb-2"
                        >
                          <span class="required">Code</span>
                        </label>
                        <el-form-item prop="session_code">
                          <input
                            type="text"
                            class="form-control form-control-lg form-control-solid"
                            name="classData.session.code"
                            v-model="classData.session.code"
                          />
                        </el-form-item>
                      </div>
                      <div>
                        <label
                          class="d-flex align-items-center fs-5 fw-bold mb-2"
                        >
                          <span class="required">Hours per week</span>
                        </label>
                        <el-form-item prop="hours_per_week">
                          <input
                            type="number"
                            class="form-control form-control-lg form-control-solid"
                            name="classData.session.hours_per_week"
                            v-model="classData.session.hours_per_week"
                          />
                        </el-form-item>
                      </div>
                    </div>
                  </div>
                </div>

                <div data-kt-stepper-element="content">
                  <div class="w-100">
                    <div class="fv-row mb-10">
                      <label
                        class="d-flex align-items-center fs-5 fw-bold mb-2"
                      >
                        <span class="title">Subject</span>
                      </label>
                    <div v-if="classData.subject.id==0">
                      <div>
                        <label
                          class="d-flex align-items-center fs-5 fw-bold mb-2"
                        >
                          <span class="required">Name</span>
                        </label>
                        <el-form-item prop="session_name">
                          <input
                            type="text"
                            class="form-control form-control-lg form-control-solid"
                            name="classData.subject.name"
                            v-model="classData.subject.name"
                          />
                        </el-form-item>
                      </div>
                      <div>
                        <label
                          class="d-flex align-items-center fs-5 fw-bold mb-2"
                        >
                          <span class="required">Code</span>
                        </label>
                        <el-form-item prop="session_code">
                          <input
                            type="text"
                            class="form-control form-control-lg form-control-solid"
                            name="classData.session.code"
                            v-model="classData.subject.code"
                          />
                        </el-form-item>
                      </div>
                      <div>
                        <label
                          class="d-flex align-items-center fs-5 fw-bold mb-2"
                        >
                          <span class="required">Time Range</span>
                        </label>
                        <el-form-item prop="timePeriod">
                          <el-time-picker
                            is-range
                            range-separator="To"
                            start-placeholder="Start time"
                            end-placeholder="End time"
                            v-model="classData.subject.timePeriod"
                            class="w-100">
                          </el-time-picker>
                        </el-form-item>
                      </div>
                      <div>
                        <label
                          class="d-flex align-items-center fs-5 fw-bold mb-2"
                        >
                          <span class="required">Hours</span>
                        </label>
                        <el-form-item prop="hours">
                          <input
                            type="number"
                            class="form-control form-control-lg form-control-solid"
                            name="classData.subject.name"
                            v-model="classData.subject.hours"
                            step=".01"
                          />
                        </el-form-item>
                      </div>
                      <div class="fv-row mb-10">
                        <label
                          class="d-flex align-items-center fs-5 fw-bold mb-2"
                        >
                          <span class="required">Instructor</span>
                        </label>
                        <el-select
                            class="form-select-solid w-100"
                            placeholder="Select option"
                            v-model="classData.subject.instructor_id"
                        >
                          <el-option v-for="instructor in instructors" 
                            :key="instructor.id" 
                            :value="instructor.id" 
                            :label="instructor.first_name+' '+instructor.last_name">
                            {{instructor.first_name}} {{instructor.last_name}}
                          </el-option>
                        </el-select>
                      </div>
                      <div class="fv-row mb-10">
                        <label
                          class="d-flex align-items-center fs-5 fw-bold mb-2"
                        >
                          <span class="required">Class days of the weeks</span>
                        </label>
                        <el-checkbox-group
                          v-model="selectedDays"
                          size="medium"
                        >
                          <el-checkbox-button
                            v-for="day in daysOfWeek"
                            :key="day.value"
                            :label="day.value"
                          >
                            {{ day.label }} 
                          </el-checkbox-button>
                        </el-checkbox-group>
                      </div>
                      <div class="fv-row mb-10">
                        <label
                          class="d-flex align-items-center fs-5 fw-bold mb-2"
                        >
                          <span class="required">Skill area</span>
                        </label>
                        <div style="display:block;">
                          <input
                            style="display:inline-block;width:80%;" 
                            type="text"
                            class="form-control form-control-lg form-control-solid"
                            name="skill"
                            v-model="skill"
                          />
                          <button type="button" class="btn btn-md btn-primary" style="display:inline-block;margin-left: 10px;" @click="addToSkillArea()">Add</button>
                        </div>
                        <div>
                          <span style="display:inline-block;margin-left:2px;margin-right:2px;" class="badge-light-success badge fs-6 fw-bolder my-2" v-for="(skill, index) in skillAreas" :key="skill">
                            {{skill}} 
                            <inline-svg src="/media/icons/duotune/arrows/arr088.svg" style="cursor:pointer;" @click="removeFromSkillArea(index)"/>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
                <div class="d-flex flex-stack pt-10">
                  <div class="me-2">
                    <button
                      type="button"
                      class="btn btn-lg btn-light-primary me-3"
                      data-kt-stepper-action="previous"
                      @click="previousStep()"
                    >
                      <span class="svg-icon svg-icon-3 me-1">
                        <inline-svg
                          src="media/icons/duotune/arrows/arr063.svg"
                        />
                      </span>
                      Back
                    </button>
                  </div>

                  <div>
                    <button
                      type="button"
                      class="btn btn-lg btn-primary"
                      v-if="currentStepIndex === totalSteps - 1"
                      @click="formSubmit()"
                    >
                      <span class="indicator-label">
                        Submit
                        <span class="svg-icon svg-icon-3 ms-2 me-0">
                          <inline-svg
                            src="media/icons/duotune/arrows/arr064.svg"
                          />
                        </span>
                      </span>
                      <span class="indicator-progress">
                        Please wait...
                        <span
                          class="
                            spinner-border spinner-border-sm
                            align-middle
                            ms-2
                          "
                        ></span>
                      </span>
                    </button>

                    <button v-else type="button" 
                      class="btn btn-lg btn-primary" 
                      @click="handleStep">
                      Continue
                      <span class="svg-icon svg-icon-3 ms-1 me-0">
                        <inline-svg
                          src="media/icons/duotune/arrows/arr064.svg"
                        />
                      </span>
                    </button>
                  </div>
                </div>
              </el-form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.el-input--suffix .el-input__inner {
  background-color: #f5f8fa;
}

.el-input__inner {
  background-color: #f5f8fa;
}
</style>

<script lang="ts">
import { defineComponent, onMounted, ref, computed, onBeforeMount } from "vue";
import { StepperComponent } from "@/assets/ts/components/_StepperComponent";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import moment from "moment";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
  name: "create-class-modal",
  components: {
  },
  props:{
    instructors: [] as any,
    daysOfWeek: [] as any,
  },
  setup() {
    const _stepperObj = ref<StepperComponent | null>(null);
    const createClassRef = ref<HTMLElement | null>(null);
    const currentStepIndex = ref(0);
    const selectedDays = ref<any>([]);
    const skillAreas = ref<any>([]);
    const skill = ref('');

    const classData = ref({
      semester : {id: 0, name:'', code:'', period: [], start_date:null, end_date:null},
      program : {id:0, name:'', code:'', semester_id : null as any},
      level : {id:0, name:'', code:'', program_id : null as any},
      session : {id:0, name:'', code:'', level_id : null as any, hours_per_week: 0},
      subject : {id:0, name:'', code:'', timePeriod: [] as any, start_time:null, end_time: null, hours:0, instructor: '', skill_area: '', class_days: '', session_id: null as any},
    });

    const semesters = ref([] as any);
    const programs = ref([] as any);
    const levels = ref([] as any);
    const sessions = ref([] as any);

    onMounted(() => {
      _stepperObj.value = StepperComponent.createInsance(
        createClassRef.value as HTMLElement
      );
    });

    const totalSteps = computed(() => {
      if (!_stepperObj.value) {
        return;
      }

      return _stepperObj.value.totatStepsNumber;
    });

    const addToSkillArea = () =>{
      if(skill.value && skill.value != ''){
        skillAreas.value.push(skill.value);
        skill.value = ''
      }
    }

    const removeFromSkillArea = (index) =>{
      skillAreas.value.splice(index, 1);
    }

    const previousStep = () => {
      if (!_stepperObj.value) {
        return;
      }

      currentStepIndex.value--;

      _stepperObj.value.goPrev();
    };

    const handleStep = () => {
      let isValidated = true;
      if(currentStepIndex.value == 0)
      {
        let selectedSemesterId = classData.value.semester.id;
        if(selectedSemesterId == 0){
          if(!classData.value.semester.code  
              || !classData.value.semester.name 
              || !classData.value.semester.period[0]
              || !classData.value.semester.period[1]){
            isValidated = false;
          }
          programs.value = [];
        }
        else{
          getPrograms(selectedSemesterId);
          classData.value.program.semester_id = selectedSemesterId;
        }
      }
      else if(currentStepIndex.value == 1)
      {
        let selectedProgramId = classData.value.program.id;
        if(selectedProgramId == 0){
          if(!classData.value.program.code || !classData.value.program.name){
            isValidated = false;
          }
          levels.value = [];
        }
        else{
          getLevels(selectedProgramId);
          classData.value.level.program_id = selectedProgramId;
        }
      }
      else if(currentStepIndex.value == 2)
      {
        let selectedLevelId = classData.value.level.id;
        if(selectedLevelId == 0){
          if(!classData.value.level.code || !classData.value.level.name){
            isValidated = false;
          }
          sessions.value = [];
        }
        else{
          getSessions(classData.value.level.id);
          classData.value.session.level_id = selectedLevelId;
        }
      }
      else if(currentStepIndex.value == 3)
      {
        let selectedSessionId = classData.value.session.id;
        if(selectedSessionId == 0){
          if(!classData.value.session.code || !classData.value.session.name){
            isValidated = false;
          }
        }
        else{
          classData.value.subject.session_id = selectedSessionId;
        }
      }

      if(isValidated == false){
        Swal.fire({
              text: "Please fill out all required fields",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });
            return false;
      }

      currentStepIndex.value++;

      if (!_stepperObj.value) {
        return;
      }

      _stepperObj.value.goNext();
    };

    const createClass = async (formData) => {
        formData.class_days = selectedDays.value.join(":");
        ApiService.setHeader();
        ApiService.post("class", formData)
            .then(() => {
              Swal.fire({
                  text: "New class is created successfully",
                  icon: "success",
                  buttonsStyling: false,
                  confirmButtonText: "Ok, got it!",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                }).then(() => {
                  window.location.reload();
                });
            })
            .catch(({ response }) => {
                console.log('createClass error => '+response.data.errors);
                Swal.fire({
                  text: "Failed to create the new class",
                  icon: "error",
                  buttonsStyling: false,
                  confirmButtonText: "Ok, got it!",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                });
            });
    }

    const formSubmit = () => {
      if(!classData.value.subject.code  
              || !classData.value.subject.name 
              || !classData.value.subject.timePeriod[0]
              || !classData.value.subject.timePeriod[1]
              || !classData.value.subject.hours){
            Swal.fire({
              text: "Please fill out all required fields",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });
            return false;
      }

      classData.value.semester.start_date = classData.value.semester.period[0];
      classData.value.semester.end_date = classData.value.semester.period[1];
      classData.value.subject.start_time = classData.value.subject.timePeriod[0].toLocaleTimeString('it-IT');
      classData.value.subject.end_time = classData.value.subject.timePeriod[1].toLocaleTimeString('it-IT');
      classData.value.subject.skill_area = skillAreas.value.join(';');
      classData.value.subject.class_days = selectedDays.value.join(';');
      createClass(classData.value);
    };

    const getSemesters = async () => {
        await new Promise((resolve, reject) => {
          ApiService.setHeader();            
            ApiService.get("class/semesters")
                .then(({ data }) => {
                    semesters.value = data.data.sort(function(a,b) { 
                          if(a.id == b.id) return 0;

                          if (Date.parse(a.start_date!) < Date.parse(b.start_date!))
                              return 1;
                          if (Date.parse(a.start_date!) > Date.parse(b.start_date!))
                              return -1;
                          return 0;
                      });
                    resolve(data.data);
                })
                .catch((error) => {
                    console.log('getSemesters error => '+error);
                    reject(new Error ('getSemesters error => '+error));
                });
        });
    }

    const getPrograms = async (id) => {
        await new Promise((resolve, reject) => {   
            ApiService.setHeader();         
            ApiService.get("class/programs/"+id)
                .then(({ data }) => {
                    programs.value = data.data;
                    resolve(data.data);
                })
                .catch((error) => {
                    console.log('getPrograms error => '+error);
                    reject(new Error ('getPrograms error => '+error));
                });
        });
    }

    const getLevels = async (id) => {
        await new Promise((resolve, reject) => { 
          ApiService.setHeader();           
            ApiService.get("class/levels/"+id)
                .then(({ data }) => {
                    levels.value = data.data;
                    resolve(data.data);
                })
                .catch((error) => {
                    console.log('getLevels error => '+error);
                    reject(new Error ('getLevels error => '+error));
                });
        });
    }

    const getSessions = async (id) => {
        await new Promise((resolve, reject) => { 
            ApiService.setHeader();           
            ApiService.get("class/sessions/"+id)
                .then(({ data }) => {
                    sessions.value = data.data;
                    resolve(data.data);
                })
                .catch((error) => {
                    console.log('getSessions error => '+error);
                    reject(new Error ('getSessions error => '+error));
                });
        });
    }

    onBeforeMount(async() => {
        await getSemesters();
    })

    return {
      handleStep,
      formSubmit,
      previousStep,
      createClassRef,
      currentStepIndex,
      totalSteps,
      classData,
      semesters,
      programs,
      levels,
      sessions,
      moment,
      selectedDays,
      skillAreas,
      addToSkillArea,
      skill,
      removeFromSkillArea
    };
  },
});
</script>

<style scoped>
* >>> .title{
    text-transform: uppercase;
    border-bottom: solid 1px lightgray;
    width: 100%;
    margin-bottom: 10px;
}

* >>> .el-checkbox-button--medium .el-checkbox-button__inner{
  padding: 10px 18px;
}
</style>

