<template>
  <div
    class="modal fade"
    id="modal_edit_class"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered mw-650px">
      <div class="modal-content">
        <div class="modal-header">
          <h2>Edit {{type}}</h2>

          <div
            class="btn btn-sm btn-icon btn-active-color-primary"
            data-bs-dismiss="modal"
            id="edit_class_close_button"
          >
            <span class="svg-icon svg-icon-1">
              <inline-svg src="/media/icons/duotune/arrows/arr061.svg" />
            </span>
          </div>
        </div>

        <div class="modal-body py-lg-10 px-lg-10">
            <div class="flex-row-fluid px-lg-15">
              <el-form
                id="kt_modal_edit_class_form"
                class="form"
                :model="formdata"
                ref="formRef"
              >
                  <div class="w-100">
                    <div class="fv-row mb-10">
                      <div>
                        <label
                          class="d-flex align-items-center fs-5 fw-bold mb-2"
                        >
                          <span class="required">Name</span>
                        </label>
                        <el-form-item prop="session_name">
                          <input
                            type="text"
                            class="form-control form-control-lg form-control-solid"
                            name="classData.subject.name"
                            v-model="formdata.name"
                          />
                        </el-form-item>
                      </div>
                      <div>
                        <label
                          class="d-flex align-items-center fs-5 fw-bold mb-2"
                        >
                          <span class="required">Code</span>
                        </label>
                        <el-form-item prop="session_code">
                          <input
                            type="text"
                            class="form-control form-control-lg form-control-solid"
                            name="classData.session.code"
                            v-model="formdata.code"
                          />
                        </el-form-item>
                      </div>
                      <div v-if="type === 'subject'">
                        <label
                          class="d-flex align-items-center fs-5 fw-bold mb-2"
                        >
                          <span class="required">Time Range</span>
                        </label>
                        <el-form-item prop="timePeriod">
                          <el-time-picker
                            is-range
                            range-separator="To"
                            start-placeholder="Start time"
                            end-placeholder="End time"
                            v-model="formdata.timePeriod"
                            class="w-100">
                          </el-time-picker>
                        </el-form-item>
                      </div>
                      <div v-if="type === 'subject'">
                        <label
                          class="d-flex align-items-center fs-5 fw-bold mb-2"
                        >
                          <span class="required">Hours</span>
                        </label>
                        <el-form-item prop="hours">
                          <input
                            type="number"
                            class="form-control form-control-lg form-control-solid"
                            name="classData.subject.name"
                            v-model="formdata.hours"
                            step=".01"
                          />
                        </el-form-item>
                      </div>
                      <div v-if="type === 'semester'">
                        <label
                          class="d-flex align-items-center fs-5 fw-bold mb-2"
                        >
                          <span class="required">Period</span>
                        </label>
                        <el-date-picker
                          v-model="formdata.period"
                          type="daterange"
                          range-separator="To"
                          start-placeholder="Start date"
                          end-placeholder="End date"
                          class="w-100"
                          format="MM/DD/YYYY">
                        </el-date-picker>
                      </div>
                      <div class="fv-row mb-10" v-if="type === 'session'">
                        <label
                          class="d-flex align-items-center fs-5 fw-bold mb-2"
                        >
                          <span class="required">Hours per week</span>
                        </label>
                        <el-form-item prop="hours">
                          <input
                            type="number"
                            class="form-control form-control-lg form-control-solid"
                            name="formdata.hours_per_week"
                            v-model="formdata.hours_per_week"
                          />
                        </el-form-item>
                      </div>
                        
                      <div class="fv-row mb-10" v-if="type === 'subject'">
                        <label
                          class="d-flex align-items-center fs-5 fw-bold mb-2"
                        >
                          <span class="required">Instructor</span>
                        </label>
                        <el-select
                            class="form-select-solid w-100"
                            placeholder="Select option"
                            v-model="formdata.instructor_id"
                        >
                          <el-option v-for="instructor in instructors" 
                            :key="instructor.id" 
                            :value="instructor.id" 
                            :label="instructor.first_name+' '+instructor.last_name">
                            {{instructor.first_name}} {{instructor.last_name}}
                          </el-option>
                        </el-select>
                      </div>
                      <div class="fv-row mb-10" v-if="type === 'subject'">
                        <label
                          class="d-flex align-items-center fs-5 fw-bold mb-2"
                        >
                          <span class="required">Class days of the weeks</span>
                        </label>
                        <el-checkbox-group
                          v-model="selectedDays"
                          size="medium"
                        >
                          <el-checkbox-button
                            v-for="day in daysOfWeek"
                            :key="day.value"
                            :label="day.value"
                          >
                            {{ day.label }} 
                          </el-checkbox-button>
                        </el-checkbox-group>
                      </div>
                      <div class="fv-row mb-10" v-if="type === 'subject'">
                        <label
                          class="d-flex align-items-center fs-5 fw-bold mb-2"
                        >
                          <span class="required">Skill area</span>
                        </label>
                        <div style="display:block;">
                          <input
                            style="display:inline-block;width:80%;" 
                            type="text"
                            class="form-control form-control-lg form-control-solid"
                            name="skill"
                            v-model="skill"
                          />
                          <button type="button" class="btn btn-md btn-primary" style="display:inline-block;margin-left: 10px;" @click="addToSkillArea()">Add</button>
                        </div>
                        <div>
                          <span style="display:inline-block;margin-left:2px;margin-right:2px;" class="badge-light-success badge fs-6 fw-bolder my-2" v-for="(skill, index) in skillAreas" :key="skill">
                            {{skill}} 
                            <inline-svg src="/media/icons/duotune/arrows/arr088.svg" style="cursor:pointer;" @click="removeFromSkillArea(index)"/>
                          </span>
                        </div>
                      </div>
                  </div>
                </div>
              <div class="d-flex flex-stack" style="float:right">
                <div>
                  <button
                    v-if="type=='semester'"
                    type="button"
                    class="btn btn-lg btn-danger"
                    style="margin-right:10px;"
                    @click="deleteSemeter()"
                    >
                    <span class="indicator-label">
                        Delete
                    </span>
                  </button>
                  <button
                    type="button"
                    class="btn btn-lg btn-primary"
                    @click="formSubmit()"
                    >
                    <span class="indicator-label">
                        Submit
                    </span>
                  </button>
              </div>
            </div>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<style lang="scss" scoped>
.el-input--suffix .el-input__inner {
  background-color: #f5f8fa;
}

.el-input__inner {
  background-color: #f5f8fa;
}
</style>

<script lang="ts">
import { defineComponent, ref, watch } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import moment from "moment";
import ApiService from "@/core/services/ApiService";
import { ElMessageBox } from "element-plus";

export default defineComponent({
  name: "edit-class-modal",
  components: {
  },
  props:{
    data:{} as any,
    type:String,
    instructors: [] as any,
    daysOfWeek: [] as any,
    getClasses : {
      type:Function,
      default: () => ({}),
    },
    updateSemester : {
      type:Function,
      default: () => ({}),
    },
  },
  setup(props) {
    const createClassRef = ref<HTMLElement | null>(null);
    const formdata = ref(props.data);
    const selectedDays = ref<any>([]);
    const skillAreas = ref<any>([]);
    const skill = ref('');
    
    watch(() => props.data, (cur, prev)=>{
        skillAreas.value = [];
        selectedDays.value = [];
        skill.value = '';
        if(cur !== prev){
            formdata.value = props.data;
            if(props.type == 'semester'){
                formdata.value.period = [];
                formdata.value.period[0] = props.data.start_date;
                formdata.value.period[1] = props.data.end_date;
            }
            else if(props.type == 'subject'){
                formdata.value.timePeriod = [];
                let startTime = new Date();

                let [start_hours, start_minutes, start_seconds] = props.data.start_time.split(':');
                startTime.setHours(start_hours);
                startTime.setMinutes(start_minutes);
                startTime.setSeconds(start_seconds);
                formdata.value.timePeriod[0] = startTime;

                let endTime = new Date();
                let [end_hours, end_minutes, end_seconds] = props.data.end_time.split(':');
                endTime.setHours(end_hours);
                endTime.setMinutes(end_minutes);
                endTime.setSeconds(end_seconds);
                formdata.value.timePeriod[1] = endTime;

                if(props.data.skill_area){
                  skillAreas.value = props.data.skill_area.split(';');
                }
                if(props.data.class_days){
                  selectedDays.value = props.data.class_days.split(';').map( Number );
                }              
            }
        }
    })

    const formSubmit = () => {
        if(props.type == 'semester'){
            formdata.value.start_date = formdata.value.period[0];
            formdata.value.end_date = formdata.value.period[1];
        }
        else if(props.type == 'subject'){
            formdata.value.start_time = formdata.value.timePeriod[0].toLocaleTimeString('it-IT');
            formdata.value.end_time = formdata.value.timePeriod[1].toLocaleTimeString('it-IT');
            formdata.value.skill_area = skillAreas.value.join(';');
            formdata.value.class_days = selectedDays.value.join(';');
            delete formdata.value.instructor;
        }

        editClass(formdata.value);
    };

    const editClass = (data) => {
      ApiService.setHeader();
      ApiService.update("class/"+props.type, data.id, data)
        .then(() => {
            Swal.fire({
                  text: "Updated successfully",
                  icon: "success",
                  buttonsStyling: false,
                  confirmButtonText: "Ok, got it!",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                }).then(() => {
                    props.getClasses();
                    let editCloseButton = document.getElementById('edit_class_close_button');
                    editCloseButton?.click();
                });
            
        })
        .catch(({ response }) => {
          console.log(response);
            Swal.fire({
                  text: "Update failed",
                  icon: "error",
                  buttonsStyling: false,
                  confirmButtonText: "Ok, got it!",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                });
        });
    };

    const addToSkillArea = () =>{
      if(skill.value && skill.value != ''){
        skillAreas.value.push(skill.value);
        skill.value = ''
      }
    }

    const removeFromSkillArea = (index) =>{
      skillAreas.value.splice(index, 1);
    }

    const deleteSemeter = () =>{
      ElMessageBox.confirm('Are you sure you want to delete this semester? Once deleted, all of the nested classes will also be deleted.')
            .then(() => {
                ApiService.setHeader();
                ApiService.delete("class/semester/"+formdata.value.id)
                  .then(() => {
                      props.updateSemester();
                      let editCloseButton = document.getElementById('edit_class_close_button');
                      editCloseButton?.click(); 
                  })
                  .catch(({ response }) => {
                    console.log(response);
                      Swal.fire({
                          text: "Delete failed.",
                          icon: "error",
                          buttonsStyling: false,
                          confirmButtonText: "Ok, got it!",
                          customClass: {
                            confirmButton: "btn btn-primary",
                          },
                      });
                  });
            });
    }

    return {
      formSubmit,
      createClassRef,
      moment,
      formdata,
      selectedDays,
      skillAreas,
      skill,
      addToSkillArea,
      removeFromSkillArea,
      deleteSemeter,
    };
  },
});
</script>
